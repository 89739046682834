<template>
  <div>
    <ul class="flex border-b">
      <li class="mr-1" :class="{'-mb-px': paid === 0 || paid === '0'}">
        <a href="#" @click.prevent="changeTab(0)" class="tab" :class="{'active': paid === 0 || paid === '0'}">Nierozliczone</a>
      </li>
      <li class="mr-1" :class="{'-mb-px': paid === 1 || paid === '1'}">
        <a href="#" @click.prevent="changeTab(1)" class="tab" :class="{'active': paid === 1 || paid === '1'}">Rozliczone</a>
      </li>
    </ul>
    <div class="flex items-center mt-2">
      <div
        v-show="paid == 1"
        class="relative mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm"
        :class="{'bg-yellow-200': payment_date_from !== ''}"
      >
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
        >data wypłaty od:</button>
        <input
          @change="setFilter"
          v-model="payment_date_from"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-2 py-1 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div
        v-show="paid == 1"
        class="relative mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm"
        :class="{'bg-yellow-200': payment_date_to !== ''}"
      >
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
        >data wypłaty do:</button>
        <input
          @change="setFilter"
          v-model="payment_date_to"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-2 py-1 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div
        v-show="paid == 0"
        class="relative mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm"
        :class="{'bg-yellow-200': order_close_date_to !== ''}"
      >
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
        >
          data zamknięcia zlecenia do
        </button>
        <input
          @change="setFilter"
          v-model="order_close_date_to"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-2 py-1 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
    </div>
    <hr class="my-1">

    <div class="w-full xl:w-3/4">
      <div v-if="isBusy">
        <div class="flex w-full py-3 px-5">
          <div class="flex items-center text-center w-full">
            <font-awesome-icon icon="spinner" size="2x" spin class="mr-3" />
            Ładuję...
          </div>
        </div>
      </div>
      <template v-else>
        <!-- Podsumowanie -->
        <div class="list-group">
          <div class="flex flex-wrap w-full py-1 px-5 border-gray-400 font-semibold">
            <div class="w-1/2 lg:w-1/3">
              Ilość zleceń: {{ salaryOrders.length }}
            </div>
            <div class="w-1/2 lg:w-1/3 text-right lg:text-left">
              Ilość pozycji: {{ allSalarySum }}
            </div>
            <div class="w-full lg:w-1/3 text-right">
              suma wynagrodzenia: {{allSalaryAmountSum | moneyPL}}
            </div>
          </div>
        </div>
        <div v-for="order in salaryOrders" :key="order.id" class="list-group">
          <div class="flex flex-wrap w-full py-1 px-5 bg-gray-200 border-b border-gray-400 font-semibold">
            <div class="w-1/2 lg:w-1/3 xl:w-1/4">
              <span class="text-gray-500">nr zlecenia:</span>
              <router-link :to="{name: 'order', params: {id: order.id}}" target="_blank">
                {{order.signature}}/{{order.order_date.substring(0, 4)}}
              </router-link>
            </div>
            <div class="w-1/2 lg:w-1/3 xl:w-1/4 text-right lg:text-left">
              <span class="text-gray-500">nr szkody:</span> {{ order.claim_no }}
            </div>
            <div class="w-1/2 lg:w-1/3 xl:w-1/4 text-left lg:text-right xl:text-left">
              <span class="text-gray-500">data zamknięcia:</span> {{ order.close_date.substring(0, 10) }}
            </div>
            <div class="w-1/2 lg:w-full xl:w-1/4 text-right">
              <span class="text-gray-500">suma:</span> {{order.salarySum | moneyPL}}
            </div>
          </div>

          <div class="hidden lg:flex flex-wrap w-full py-0 px-5 text-gray-500 border-b border-dashed border-gray-300">
            <div class="w-2/3">
              typ wynagrodzenia <span class="text-sm"><em>(opis dod.)</em></span>
            </div>
            <div class="w-1/6">
              data realizacji
            </div>
            <div class="w-1/6 text-right">
              kwota
            </div>
          </div>
          <div v-for="salary in order.salaries" :key="salary.id" class="flex flex-wrap w-full py-0 px-5">
            <div class="w-full lg:w-2/3">
              {{ salary.salary_type.name }} <span v-if="salary.description" class="text-sm"><em>({{salary.description}})</em></span>
            </div>
            <div class="w-1/2 lg:w-1/6">
              <span v-if="salary.payment_date">{{salary.payment_date}}</span>
              <span v-else class="text-gray-500"><em>oczekuje</em></span>
            </div>
            <div class="w-1/2 lg:w-1/6 text-right font-mono">
              {{ salary.amount | moneyPL }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';
import SalaryService from '../services/SalaryService';

export default {
  name: 'ExpertSalaries',
  mixins: [errorHandler, confirm],
  data() {
    return {
      isBusy: true,
      query: {},
      payment_date_from: '',
      payment_date_to: '',
      order_close_date_to: '',
      paid: 0,
      salaryOrders: [],
      allSalaryAmountSum: 0,
      allSalarySum: 0,
    };
  },
  mounted() {
    this.setQueryFromUri();
  },
  methods: {
    setQueryFromUri() {
      let changed = 0;
      if (this.$route.query.paid === undefined) {
        this.query.paid = 0;
        this.query.order_close_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        this.order_close_date_to = this.query.order_close_date_to;
        changed += 1;
      } else {
        this.query.paid = this.$route.query.paid;
        if (this.query.paid === 1 || this.query.paid === '1') {
          if (this.$route.query.payment_date_to !== undefined) {
            this.query.payment_date_to = this.$route.query.payment_date_to;
          } else {
            this.query.payment_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            changed += 1;
          }
          this.payment_date_to = this.query.payment_date_to;
          if (this.$route.query.payment_date_from !== undefined) {
            this.query.payment_date_from = this.$route.query.payment_date_from;
          } else {
            this.query.payment_date_from = moment(this.payment_date_to).startOf('month').format('YYYY-MM-DD');
            changed += 1;
          }
          this.payment_date_from = this.query.payment_date_from;
        } else if (this.query.paid === 0 || this.query.paid === '0') {
          if (this.$route.query.order_close_date_to !== undefined) {
            this.query.order_close_date_to = this.$route.query.order_close_date_to;
          } else {
            this.query.order_close_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            changed += 1;
          }
          this.order_close_date_to = this.query.order_close_date_to;
        }
      }

      this.paid = this.query.paid;

      if (changed > 0) {
        const { name, params } = this.$route;
        this.$router.replace({ name, params, query: this.query });
      } else {
        this.getEmployeeSalaries();
      }
    },
    getEmployeeSalaries() {
      const query = {
        paid: this.query.paid,
      };
      if (this.paid === 1 || this.paid === '1') {
        query.payment_date_from = this.query.payment_date_from;
        query.payment_date_to = this.query.payment_date_to;
      } else {
        query.order_close_date_to = this.query.order_close_date_to;
      }
      SalaryService.getExpertSalaries(query)
        .then(response => {
          this.salaryOrders = response.data.salaries;
          this.allSalaryAmountSum = 0;
          this.allSalarySum = 0;
          this.salaryOrders.forEach(obj => {
            let orderAmountSum = 0;
            obj.salaries.forEach(sal => {
              orderAmountSum += Number(sal.amount);
              this.allSalarySum += 1;
            });
            // eslint-disable-next-line no-param-reassign
            obj.salarySum = orderAmountSum;
            this.allSalaryAmountSum += orderAmountSum;
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    changeTab(paid) {
      if (paid === 1) {
        if (this.payment_date_to === '') {
          this.payment_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        }
        if (this.payment_date_from === '') {
          this.payment_date_from = moment(this.payment_date_to).startOf('month').format('YYYY-MM-DD');
        }
        this.query.payment_date_from = this.payment_date_from;
        this.query.payment_date_to = this.payment_date_to;
      } else {
        this.query.payment_date_to = this.payment_date_to;
      }
      this.paid = paid;
    },
    setFilter() {
      Object.assign(this.query, this.$route.query);
      this.query.paid = this.paid;
      delete this.query.payment_date_from;
      delete this.query.payment_date_to;
      delete this.query.report_date_to;
      if (this.paid === 1 || this.paid === '1') {
        this.query.payment_date_from = this.payment_date_from;
        this.query.payment_date_to = this.payment_date_to;
      } else if (this.paid === 0 || this.paid === '0') {
        this.query.order_close_date_to = this.order_close_date_to;
      }
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.query)) {
        this.$router.push({ name: 'expertSalaries', query: this.query });
      }
    },
  },
  watch: {
    $route(to, from) {
      if (JSON.stringify(to.query) !== JSON.stringify(from.query)) {
        if (JSON.stringify(to.query) === '{}') {
          this.setQueryFromUri();
        } else {
          this.getEmployeeSalaries();
        }
      }
    },
    paid: 'setFilter',
  },
};
</script>

<style lang="scss" scoped>
  .list-group {
    @apply mt-2;
    @apply border;
    @apply rounded-sm;
    @apply border-gray-400;
  }
  .tab {
    @apply bg-white;
    @apply inline-block;
    @apply h-full;
    @apply py-2;
    @apply px-4;
    @apply font-semibold;
    &.active {
      @apply text-gray-700;
      @apply cursor-default;
      @apply border-l;
      @apply border-t;
      @apply border-r;
      @apply rounded-t;
      &:hover {
        @apply text-gray-700;
        @apply no-underline;
      }
    }
    &:not(.active) {
      @apply cursor-pointer;
      @apply text-blue-500;
      &:hover {
        @apply text-blue-800;
      }
    }
  }
</style>
